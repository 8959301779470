import $ from 'jquery';

import 'materialize-css';

import 'font-awesome/css/font-awesome.css';
import 'materialize-css/dist/css/materialize.css';

import '../styles/style.styl';

(function app() {
  // https://materializecss.com/sidenav.html
  setTimeout(function (){ $('.sidenav').sidenav();},1000);
}());
